import lodash from 'lodash';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Loader, Segment, Message, Menu, Dropdown, Header, Input, Grid, Form } from 'semantic-ui-react';
import { confirm } from 'shared/globalModal';
import useRestResource from 'shared/hooks/useRestResource';
import modelEditor from 'http/modelEditor';
import NodeMetadata, { dataTypeIsPrimitive, dataTypes, nodeMetadata } from 'model-editor/nodeMetadata';
import GraphView from 'model-editor/molecules/GraphView/GraphView';
import NodeFieldViewer from 'model-editor/molecules/NodeFieldViewer';
import NodeMultiselect from 'model-editor/atoms/NodeMultiselect';
import withNavShell from 'model-editor/molecules/withNavShell';

import NodeData from './NodeData';
import TaxonomyOptions from './TaxonomyOptions';
import styles from './ProductsView.module.css';

const TABS = {
    Meta: {
        name: 'Meta',
        icon: 'dna',
    },
    Forbindelser: {
        name: 'Forbindelser',
        icon: 'code branch',
    },
    Nodedata: {
        name: 'Nodedata',
        icon: 'cog',
    },
    Taksonomi: {
        name: 'Taksonomi',
        icon: 'sitemap',
    },
};

const ProductsView = ({ updateNavShell }) => {
    const { modelId, nodeId } = useParams();

    const [activeTab, setActiveTab] = useState(TABS.Meta.name);
    const [working, setWorking] = useState(false);
    const nodeDataRef = useRef();
    const history = useHistory();

    useEffect(() => {
        updateNavShell();
    }, [updateNavShell]);

    const fullNode = useRestResource({
        fetcher: modelEditor.getNodeByID,
        args: [modelId, nodeId],
    });

    const nodeNeighbours = useRestResource({
        fetcher: modelEditor.getNodeNeighbours,
        args: [modelId, nodeId],
    });

    const allNodes = useRestResource({
        fetcher: modelEditor.getModelNodesMap,
        args: [modelId],
    });

    const restResources = [fullNode, nodeNeighbours, allNodes];
    const hasErrors = restResources.some(restItem => restItem.error);
    const isLoading = restResources.some(restItem => !restItem.data);
    const isWorking = restResources.some(restItem => restItem.loading) || working;

    const currentNode = useMemo(() => {
        return {
            ...fullNode.data,
            edges: nodeNeighbours.leftNodes || [],
        };
    }, [fullNode.data, nodeNeighbours.leftNodes]);

    const graph = useMemo(() => {
        if (isLoading) {
            return;
        }

        const { leftNodes, rightNodes } = nodeNeighbours.data;
        const nodeMap = allNodes.data;
        const nodes = [currentNode];
        const edges = [];

        for (let leftNodeID of leftNodes) {
            edges.push({
                from: currentNode.id,
                to: leftNodeID,
            });
            nodes.push(nodeMap[leftNodeID]);
        }

        for (let rightNodeID of rightNodes) {
            edges.push({
                from: rightNodeID,
                to: currentNode.id,
            });
            nodes.push(nodeMap[rightNodeID]);
        }

        return {
            nodes,
            edges,
        };
    }, [isLoading, currentNode, nodeNeighbours.data, allNodes.data]);

    const graphJSX = useMemo(() => {
        if (!graph) {
            return null;
        }

        return (
            <GraphView
                editor
                modelId={modelId}
                graph={graph}
                working={isWorking}
                style={{
                    width: '100%',
                    height: '350px',
                }}
            />
        );
    }, [modelId, graph, isWorking]);

    const getNodeStyle = () => {
        const meta = NodeMetadata(currentNode.type);
        const color = meta.color;
        return { color };
    };

    const getTabVisibilityStyles = tabName => {
        return {
            display: activeTab === tabName ? 'block' : 'none',
        };
    };

    const injectNodeData = async node => {
        const nodeCopy = { ...node };
        const nodeData = nodeDataRef?.current;

        if (typeof nodeData?.getData === 'function') {
            nodeCopy.data = await nodeData.getData();
        }

        if (typeof nodeData?.getView === 'function') {
            nodeCopy.viewId = nodeData.getView();
        }

        if (typeof nodeData?.getDatatype === 'function') {
            nodeCopy.dataType = nodeData.getDatatype();
        }

        return nodeCopy;
    };

    const getValidLeftsideNodes = () => {
        if (!currentNode) {
            return [];
        }

        const meta = NodeMetadata(currentNode.type);
        const allowedDataTypes = meta.allowedDependencyDataTypes;
        const allowedNodeTypes = meta.allowedDependencyNodeTypes;
        const validNodes = [];

        for (let node of Object.values(allNodes.data)) {
            if (node.id === currentNode.id) {
                continue;
            }

            if (allowedDataTypes.length > 0 && !allowedDataTypes.includes(node.dataType)) {
                continue;
            }
            
            if (allowedNodeTypes.length > 0 && !allowedNodeTypes.includes(node.type)) {
                continue;
            }

            validNodes.push(node);
        }

        return validNodes;
    };

    const getValidRightsideNodes = () => {
        if (!currentNode) {
            return [];
        }
        
        const validNodes = [];

        for (let node of Object.values(allNodes.data)) {
            if (node.id === currentNode.id) {
                continue;
            }

            const meta = NodeMetadata(node.type);
            const allowedDataTypes = meta.allowedDependencyDataTypes;
            const allowedNodeTypes = meta.allowedDependencyNodeTypes;

            if (allowedDataTypes.length > 0 && !allowedDataTypes.includes(currentNode.dataType)) {
                continue;
            }
            
            if (allowedNodeTypes.length > 0 && !allowedNodeTypes.includes(currentNode.type)) {
                continue;
            }

            validNodes.push(node);
        }

        return validNodes;
    };

    const setNodeData = (path, value) => {
        const newNodeData = { ...currentNode };
        lodash.set(newNodeData, path, value);
        fullNode.setLocalData(newNodeData);
    };

    const makeNodeSelectionHandler = prop => {
        return nodeID => {
            const newNodeNeighbours = { ...nodeNeighbours.data };
            const set = newNodeNeighbours[prop];
            if (set.has(nodeID)) {
                set.delete(nodeID);
            } else {
                set.add(nodeID);
            }
            nodeNeighbours.setLocalData(newNodeNeighbours);
        };
    };

    const writeNodeUpdates = async () => {
        const { leftNodes, rightNodes } = nodeNeighbours.data;
        const nodeWithData = await injectNodeData(currentNode);

        setWorking(true);
        await Promise.all([
            modelEditor.updateNode(modelId, nodeId, nodeWithData),
            modelEditor.updateNodeNeighbours(modelId, nodeId, leftNodes, rightNodes),
        ]);
        allNodes.refetch();
        fullNode.refetch();
        setWorking(false);
    };

    const deleteNode = async () => {
        if (await confirm('Er du sikker?')) {
            await modelEditor.deleteNode(modelId, nodeId);
            history.push(`/model-editor/${modelId}/nodes`);
        }
    };

    const renderNodeTypeDropdown = () => {
        const isPrimitive = dataTypeIsPrimitive(currentNode.dataType);
        const nodeTypes = Object.values(nodeMetadata);
        const nodesOptions = (
            nodeTypes
            .filter(n => n.dataType === currentNode.dataType)
            .map(({ type }) => ({
                key: type,
                text: type,
                value: type,
            }))
        );
        return (
            <Dropdown
                closeOnChange
                selection
                search
                fluid
                disabled={!isPrimitive}
                options={nodesOptions}
                defaultValue={currentNode.type}
                onChange={(_, { value }) => setNodeData('type', value)}
            />
        );
    };

    const renderMenu = () => {
        const meta = NodeMetadata(currentNode.type);
        const isDeleteBtnDisabled = meta.immutable;

        return <Menu inverted pointing>
            {/* tabs */}
            {Object.values(TABS).map(({ name, icon }) => <Menu.Item
                content={name}
                icon={icon}
                active={activeTab === name}
                onClick={() => setActiveTab(name)}
            />)}

            {/* node options */}
            <Menu.Menu position='right'>
                <Menu.Item>
                    <Button
                        content='Opdater node'
                        icon='save'
                        onClick={() => writeNodeUpdates()}
                        primary
                    />
                </Menu.Item>
                <Menu.Item>
                    <Button
                        content='Slet node'
                        icon='delete'
                        color='red'
                        onClick={deleteNode}
                        disabled={isDeleteBtnDisabled}
                    />
                </Menu.Item>
            </Menu.Menu>
        </Menu>;
    };

    const renderMetaTab = () => {
        return (
            <div style={getTabVisibilityStyles(TABS.Meta.name)}>
                <Form inverted style={{ width: '100%' }}>
                    <Form.Field>
                        <label>Navn</label>
                        <Input
                            defaultValue={currentNode.name}
                            onChange={(_, { value }) => setNodeData('name', value)}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Tag</label>
                        <Input
                            defaultValue={currentNode.tag}
                            onChange={(_, { value }) => {
                                setNodeData('tag', value)
                            }}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Nodetype</label>
                        {renderNodeTypeDropdown()}
                    </Form.Field>
                </Form>
            </div>
        );
    };

    const renderConnectionsTab = () => {
        if (activeTab !== TABS.Forbindelser.name) {
            return;
        }

        const validLeftNodes = getValidLeftsideNodes();
        const validRightNodes = getValidRightsideNodes();
        
        return (
            <div style={getTabVisibilityStyles(TABS.Forbindelser.name)}>
                <Grid columns={2}>
                    {[[validLeftNodes, 'leftNodes', 'venstre'], [validRightNodes, 'rightNodes', 'højre']].map(nodeInfo => {
                        const [validNodes, selectedNodesProp, orientation] = nodeInfo;
                        const selectedNodes = nodeNeighbours.data[selectedNodesProp];
                        const plur = selectedNodes.size !== 1;

                        return (
                            <Grid.Column key={orientation}>
                                <Header inverted>{selectedNodes.size} {orientation}vendt{plur && 'e'} forbindelse{plur && 'r'}</Header>
                                <NodeMultiselect
                                    nodes={validNodes}
                                    selected={selectedNodes}
                                    onNodeClicked={makeNodeSelectionHandler(selectedNodesProp)}
                                />
                            </Grid.Column>
                        );
                    })}
                </Grid>
            </div>
        );
    };

    const renderNodeDataTab = () => {
        return (
            <div style={getTabVisibilityStyles(TABS.Nodedata.name)}>
                {
                    isWorking && <Loader inline='centered' active />
                }
                {
                    !isWorking &&
                    <NodeData
                        model={graph}
                        node={currentNode}
                        nodeDataRef={nodeDataRef}
                        allNodes={Object.values(allNodes.data)}
                    />
                }
            </div>
        );
    };

    const renderTaxonomyTab = () => {
        return (
            <div style={getTabVisibilityStyles(TABS.Taksonomi.name)}>
                <TaxonomyOptions
                    nodeData={fullNode.data}
                    setNodeData={fullNode.setLocalData}
                    dateNodes={Object.values(allNodes.data || {}).filter(node => node.dataType === dataTypes.DATE)}
                />
            </div>
        );
    };

    if (hasErrors) {
        return <Message error content='An error occured' />;
    }

    if (isLoading) {
        return <Loader inline='centered' active />;
    }

    return (
        <div className={styles.productView}>
            <div className={styles.model}>
                {graphJSX}
                <div className={styles.editor}>
                    <div>
                        <h1 className={styles.mainHeader}>
                            {currentNode.name}
                            &nbsp;
                            (<span
                                style={getNodeStyle()}
                                className={styles.typeHeader}
                                children={currentNode.type}
                            />)
                        </h1>
                    </div>
                    {renderMenu()}
                    <section className={styles.nodeSettings}>
                        <Segment inverted key={currentNode.id}>
                            {renderMetaTab()}
                            {renderConnectionsTab()}
                            {renderNodeDataTab()}
                            {renderTaxonomyTab()}
                        </Segment>
                        <NodeFieldViewer
                            nodeID={currentNode.id}
                            modelID={modelId}
                            beforeLink={writeNodeUpdates}
                        />
                    </section>
                </div>
            </div>
        </div>
    );
};

export default withNavShell(ProductsView);