import React, { useEffect, useMemo, useState } from 'react';
import { Dropdown, Form, Loader, Message, Search, Segment } from 'semantic-ui-react';
import { dataTypes } from 'model-editor/nodeMetadata';
import modelEditor from 'http/modelEditor';
import taxonomyParser from 'http/taxonomyParser';
import useRestResource from 'shared/hooks/useRestResource';

const allowedDataTypes = {
    [dataTypes.NUMBER]:      tagMeta => ['xbrli:monetaryItemType', 'xbrli:decimalItemType'].includes(tagMeta.type),
    [dataTypes.STRING]:      tagMeta => ['xbrli:stringItemType', 'nonnum:domainItemType'].includes(tagMeta.type),
    [dataTypes.ENUM_STRING]: tagMeta => tagMeta.typeSpecification?.validValues?.length > 0,
    [dataTypes.STRING_LIST]: tagMeta => ['xbrli:stringItemType', 'nonnum:domainItemType'].includes(tagMeta.type),
    [dataTypes.BOOLEAN]:     tagMeta => ['xbrli:booleanItemType'].includes(tagMeta.type),
    [dataTypes.DATE]:        tagMeta => ['xbrli:dateItemType'].includes(tagMeta.type),
};

const fetchTaxonomyTags = async modelID => {
    const { taxonomy, specifyTaxonomyPeriodsManually } = await modelEditor.getModelMetadata(modelID);
    if (!taxonomy) {
        return null;
    }

    const tags = await taxonomyParser.listTags(taxonomy);

    const tagsByID = {};
    for (const tag of tags) {
        tagsByID[tag.id] = tag;
    }

    return {
        taxonomy,
        specifyTaxonomyPeriodsManually,
        tagsByID,
    };
};

const TaxonomyOptions = ({ nodeData, setNodeData, dateNodes }) => {
    const [mounted, setMounted] = useState(false);
    const [tagQuery, setTagQuery] = useState(nodeData?.taxonomyConfig?.mainTag);
    const [memberTagDimensionIds, setMemberTagDimensionIds] = useState([]);
    const [memberTagIds, setMemberTagIds] = useState([]);

    const {
        isPrimo,
        mainTagID,
        dimensionTagID,
        memberTagID,
        startDateNodeID,
        endDateNodeID,
        instantDateNodeID,
    } = nodeData.taxonomyConfig || {};

    const taxonomyTagsResource = useRestResource({
        fetcher: fetchTaxonomyTags,
        args: [nodeData?.modelID],
    });

    const updateTaxonomyData = (updator = {}) => {
        const { taxonomyConfig, ...restOfTheData } = nodeData;

        const newTaxonomyData = {
            ...(taxonomyConfig || {}),
            ...updator,
        };

        setNodeData({
            taxonomyConfig: newTaxonomyData,
            ...restOfTheData,
        });
    };

    useEffect(() => {
        if (mounted) return;
        if (!taxonomyTagsResource.data) return;

        setMounted(true);

        const mainTagID = nodeData?.taxonomyConfig?.mainTagID;
        if (!mainTagID) return;

        const defaultMainTagQuery = taxonomyTagsResource.data.tagsByID[mainTagID]?.name;
        setTagQuery(defaultMainTagQuery);
    }, [taxonomyTagsResource.data, nodeData?.taxonomyConfig?.mainTagID, mounted]);

    const relevantMainTags = useMemo(() => {
        if (!(nodeData.dataType in allowedDataTypes)) {
            return [];
        }

        if (!tagQuery) {
            return [];
        }

        if (!taxonomyTagsResource.data) {
            return [];
        }

        const tagQueryLowerCase = tagQuery.toLowerCase();

        return Object.values(taxonomyTagsResource.data.tagsByID).filter(tag => {
            if (tag.isAbstract) {
                return false;
            }

            if (!allowedDataTypes[nodeData.dataType](tag)) {
                return false;
            }

            const searchMatchByNameOrLabel = (
                tag.name.toLowerCase().includes(tagQueryLowerCase) ||
                tag.label.toLowerCase().includes(tagQueryLowerCase)
            );

            if (!searchMatchByNameOrLabel) {
                return false;
            }

            return true;
        }).slice(0, 5);
    }, [taxonomyTagsResource.data, nodeData.dataType, tagQuery]);

    useEffect(() => {
        setMemberTagDimensionIds([]);

        if (!mainTagID) {
            return;
        }

        if (!taxonomyTagsResource.data) {
            return;
        }

        const doFetchMemberTagDimenions = async () => {
            const { id } = taxonomyTagsResource.data.tagsByID[mainTagID];
            const memberTagDimensions = await taxonomyParser.getMemberDimensionsByTag(taxonomyTagsResource.data.taxonomy, id);
            setMemberTagDimensionIds(memberTagDimensions);
        };

        doFetchMemberTagDimenions();
    }, [taxonomyTagsResource.data, mainTagID]);

    useEffect(() => {
        setMemberTagIds([]);

        if (!dimensionTagID) {
            return;
        }

        if (!taxonomyTagsResource.data) {
            return;
        }

        const doFetchMemberTags = async () => {
            const { id } = taxonomyTagsResource.data.tagsByID[dimensionTagID];
            const memberTags = await taxonomyParser.getAllChildrenByTag(taxonomyTagsResource.data.taxonomy, id);
            setMemberTagIds(memberTags.filter(tagID => !!tagID));
        };

        doFetchMemberTags();
    }, [taxonomyTagsResource.data, dimensionTagID]);

    const setMainTagID = mainTagID => {
        updateTaxonomyData({
            mainTagID,
            dimensionTagID: '',
            memberTagID: '',
        });
    };

    if (taxonomyTagsResource.loading) {
        return (
            <Loader
                size='big'
                inline='centered'
                active
            />
        );
    }

    if (taxonomyTagsResource.error) {
        return (
            <Message
                error
                icon='warning sign'
                content={taxonomyTagsResource.error.message}
            />
        );
    };

    if (!taxonomyTagsResource.data) {
        return (
            <Message
                icon='info circle'
                content='Ingen taksonomi valgt under "Metadata"'
            />
        );
    }
    

    if (!(nodeData.dataType in allowedDataTypes)) {
        return <span>Denne node kan ikke konfigureres i henhold til taksonomien</span>;
    }

    const mainTagDescription = taxonomyTagsResource.data.tagsByID[mainTagID];

    const dateNodeOptions = dateNodes.map(node => {
        return {
            text: node.name,
            value: node.id,
        };
    });

    return (
        <Segment>
            <Form>
                <Form.Field error={tagQuery && !mainTagID}>
                    <label>XBRL-tag</label>
                    <Search
                        fluid
                        placeholder='Søg efter tag...'
                        noResultsMessage='Ingen tags fundet...'
                        defaultValue={mainTagDescription?.label}
                        onResultSelect={(_, { result }) => setMainTagID(result.tagID)}
                        onSearchChange={(_, { value }) => {
                            setTagQuery(value);
                            if (mainTagID) {
                                setMainTagID('');
                            }
                        }}
                        results={relevantMainTags.map(tag => {
                            return {
                                tagID: tag.id,
                                title: tag.label,
                                description: tag.name,
                            };
                        })}
                    />
                </Form.Field>

                {mainTagDescription && taxonomyTagsResource.data.specifyTaxonomyPeriodsManually && (
                    <>
                        {mainTagDescription.periodType === 'duration' && (
                            <Form.Group widths='equal'>
                                <Form.Dropdown
                                    selection
                                    options={dateNodeOptions}
                                    label='Relevant periode startdato'
                                    defaultValue={startDateNodeID}
                                    onChange={(_, { value }) => updateTaxonomyData({ startDateNodeID: value })}
                                />
                                <Form.Dropdown
                                    selection
                                    options={dateNodeOptions}
                                    label='Relevant periode slutdato'
                                    defaultValue={endDateNodeID}
                                    onChange={(_, { value }) => updateTaxonomyData({ endDateNodeID: value })}
                                />
                            </Form.Group>
                        )}

                        {mainTagDescription.periodType === 'instant' && (
                            <Form.Dropdown
                                selection
                                options={dateNodeOptions}
                                label='Vælg dato'
                                defaultValue={instantDateNodeID}
                                onChange={(_, { value }) => updateTaxonomyData({ instantDateNodeID: value })}
                            />
                        )}
                    </>
                )}

                {
                    mainTagDescription?.isBalance && 
                    <Form.Field>
                        <label>Tidsangivelse</label>
                        <Dropdown
                            selection
                            value={!!isPrimo}
                            onChange={(_, { value }) => updateTaxonomyData({ isPrimo: value })}
                            options={[
                                {
                                    text: 'Ultimo',
                                    value: false,
                                },
                                {
                                    text: 'Primo',
                                    value: true,
                                },
                            ]}
                        />
                    </Form.Field>
                }
                {
                    memberTagDimensionIds.length > 0 &&
                    <Form.Field>
                        <label>Dimension</label>
                        <Dropdown
                            selection
                            search
                            clearable
                            value={dimensionTagID}
                            onChange={(_, { value }) => updateTaxonomyData({ dimensionTagID: value })}
                            options={memberTagDimensionIds.map(tagID => {
                                const dimensionTag = taxonomyTagsResource.data.tagsByID[tagID];
                                return {
                                    text: dimensionTag.label,
                                    description: dimensionTag.name,
                                    value: dimensionTag.id,
                                };
                            })}
                        />
                    </Form.Field>
                }
                {
                    memberTagIds.length > 0 &&
                    <Form.Field>
                        <label>Member</label>
                        <Dropdown
                            selection
                            search
                            clearable
                            value={memberTagID}
                            onChange={(_, { value }) => updateTaxonomyData({ memberTagID: value })}
                            options={memberTagIds.map(tagID => {
                                const memberTag = taxonomyTagsResource.data.tagsByID[tagID];
                                return {
                                    text: memberTag.label,
                                    description: memberTag.name,
                                    value: memberTag.id,
                                };
                            })}
                        />
                    </Form.Field>
                }
            </Form>
        </Segment>
    );
}

export default TaxonomyOptions;